exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-test-tsx": () => import("./../../../src/pages/articles-test.tsx" /* webpackChunkName: "component---src-pages-articles-test-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-home-about-about-section-tsx": () => import("./../../../src/pages/_home/about/AboutSection.tsx" /* webpackChunkName: "component---src-pages-home-about-about-section-tsx" */),
  "component---src-pages-home-last-articles-last-articles-tsx": () => import("./../../../src/pages/_home/last_articles/LastArticles.tsx" /* webpackChunkName: "component---src-pages-home-last-articles-last-articles-tsx" */),
  "component---src-pages-home-studies-studies-section-tsx": () => import("./../../../src/pages/_home/studies/StudiesSection.tsx" /* webpackChunkName: "component---src-pages-home-studies-studies-section-tsx" */),
  "component---src-pages-home-work-work-section-tsx": () => import("./../../../src/pages/_home/work/WorkSection.tsx" /* webpackChunkName: "component---src-pages-home-work-work-section-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */)
}

